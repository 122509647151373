import Vue from "vue";
import App from "./App.vue";
import i18n from "@/utils/i18n/i18n.js";
import "./elementUi/index";

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  i18n,
}).$mount("#app");
