export default {
  title: "DrawNote",
  nav1: "Home",
  nav2: "User Manuals",
  nav3: "DrawNote",
  download: "Download",
  lang: "中",
  swiperText1: "All-in-one",
  swiperText2: "Feature-rich notebook & notepad",
  swiperText3:
    "integrates note-taking, mind mapping, to-do list, handwriting, sketching, drawing and painting",
  swiperButton: "Free Download",
  dialogButton1: "Go app market",
  dialogButton2: "Go App Store",

  footerNumber:
    "",
};
