<template>
  <el-container class="home-page">
    <!-- 头部 -->
    <el-header class="head">
      <div class="show-box">
        <el-row style="margin-bottom: 10px">
          <el-col :span="6" style="text-align: left">
            <div>
              <el-image
                :src="require('./assets/logo.png')"
                style="
                  float: left;
                  width: 45px;
                  height: 45px;
                  margin-left: 20px;
                  margin-right: 10px;
                "
              ></el-image>
              <span>{{ $t("title") }}</span>
            </div>
          </el-col>

        </el-row>
      </div>
      <!-- 宽度小的时候显示这个 -->
      <div class="show-box small">
        <el-row style="margin-bottom: 5px">
          <el-col :span="11" style="text-align: left">
            <div>
              <el-image
                :src="require('./assets/logo.png')"
                style="
                  float: left;
                  width: 45px;
                  height: 45px;
                  margin-left: 20px;
                  margin-right: 10px;
                "
              ></el-image>
              <span>{{ $t("title") }}</span>
            </div>
          </el-col>

        </el-row>

      </div>
    </el-header>
    <!-- 主题区域 -->
    <el-main class="home-main">
      <el-row class="swiper">
        <el-row> {{ $t("swiperText1") }} </el-row>
        <el-row> {{ $t("swiperText2") }}</el-row>
        <el-row style="font-size: 16px; font-weight: 400">
          {{ $t("swiperText3") }}
        </el-row>

        <el-row>
          <button
            class="download"
            round
            style="width: 156px; height: 41px"
            @click="goMarket"
          >
            {{ $t("swiperButton") }}
          </button>
        </el-row>
      </el-row>
      <div>
        <el-row style="text-align: center">
          <el-row class="swiperBox">
            <el-col :span="6" class="swiperLi">
              <el-image
                :preview-src-list="srcList"
                :src="require('./assets/guide01.png')"
              ></el-image>
            </el-col>
            <el-col :span="6" class="swiperLi">
              <el-image
                :preview-src-list="srcList"
                :src="require('./assets/guide02.png')"
              >
              </el-image>
            </el-col>
            <el-col :span="6" class="swiperLi">
              <el-image
                :preview-src-list="srcList"
                :src="require('./assets/guide03.png')"
              ></el-image>
            </el-col>
            <el-col :span="6" class="swiperLi">
              <el-image
                :preview-src-list="srcList"
                :src="require('./assets/guide04.png')"
              ></el-image>
            </el-col>
            <el-col :span="6" class="swiperLi">
              <el-image
                :preview-src-list="srcList"
                :src="require('./assets/guide05.png')"
              ></el-image>
            </el-col>
            <el-col :span="6" class="swiperLi">
              <el-image
                :preview-src-list="srcList"
                :src="require('./assets/guide06.png')"
              ></el-image>
            </el-col>
            <el-col :span="6" class="swiperLi">
              <el-image
                :preview-src-list="srcList"
                :src="require('./assets/guide07.png')"
              ></el-image>
            </el-col>
          </el-row>
        </el-row>
      </div>
    </el-main>
    <el-footer class="footer">
      <div
        style="
          margin-top: 15px;
          padding-top: 15px;
          padding-bottom: 15px;
          border-top: 1px solid rgb(95, 100, 100);
          font-size: 15px;
          color: rgb(95, 100, 100);
        "
      >
        {{ $t("footerNumber") }}
        <a href="https://beian.miit.gov.cn"
           style="
          padding-left: 15px;
          font-size: 15px;
          color: rgb(95, 100, 100);
        "
           target="_blank"
        >{{ $t("footerNumber_icp") }}</a>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: "homePage",
  data() {
    return {
      srcList: [
        require("./assets/guide01.png"),
        require("./assets/guide02.png"),
        require("./assets/guide03.png"),
        require("./assets/guide04.png"),
        require("./assets/guide05.png"),
        require("./assets/guide06.png"),
        require("./assets/guide07.png"),
      ],
      urlManual: "https://1993hzw.github.io/dragonnest/drawnote/manual/",
    };
  },
  mounted() {
    // localStorage.getItem("noteLanguage") &&
    //   (this.$i18n.locale = localStorage.getItem("noteLanguage"));
  },
  methods: {
    changeLang() {
      // console.log(this.$i18n.locale);
      this.$i18n.locale === "en"
        ? (this.$i18n.locale = "cn")
        : (this.$i18n.locale = "en");
      localStorage.setItem("noteLanguage", this.$i18n.locale);
    },
    goMarket() {
      function isMobileDevice() {
          return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      }
      function isInWeChat() {
          return /MicroMessenger/i.test(navigator.userAgent);
      }

      if (isMobileDevice()) {
        if(isInWeChat()) {
          alert("请用浏览器打开该链接，或者在移动端的应用商店里搜索并下载“吾绘笔记”")
        } else {
          window.location.href = 'market://details?id=com.dragonnest.drawnote';
        }
      } else {
        alert("请在移动端的应用商店里搜索并下载“吾绘笔记")
      }
     
    }
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 800px) {
  .show-box {
    display: none !important;
  }
  .show-box.small {
    display: block !important;
  }
  .swiper {
    text-align: center !important;
    .el-row {
      &:first-child {
        font-size: 25px !important;
      }
      &:nth-child(2) {
        font-size: 20px !important;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .swiper {
    padding: 50px 30px 0 !important;
  }
}
@media screen and (max-width: 500px) {
  .swiper {
    padding: 40px 30px 0 !important;
  }
}
.head {
  margin-top: 0px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: rgb(51, 51, 51);
  background-color: #fff;
  .show-box {
    display: block;
    &.small {
      display: none;
      .el-button {
        margin-left: 0 !important;
      }
    }
    .el-row {
      display: flex;
      align-items: center;
      height: 48px;
      line-height: 48px;
      padding: 0px;
      .head-right {
        float: right;
        a {
          margin-right: 25px;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          color: rgb(51, 51, 51);
        }
        .el-button {
          text-align: center;
          margin-left: 10px;
          margin-right: 20px;
          border-radius: 26px;
          font-size: 13px;
          padding: 5px 15px;
          color: rgb(253, 102, 55);
          border: 1px solid rgb(253, 102, 55);
          background: rgb(255, 255, 255);
        }
      }

      .head-bottom {
        background-color: #f6f6f6;
        a {
          margin: 0 10px;
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          color: rgb(51, 51, 51);
        }
      }
    }
  }
}
.home-main {
  max-width: 1080px;
  margin: 0 auto;
  text-align: center;
  .swiper {
    text-align: center;
    height: 400px;
    padding: 60px 60px 0;
    .el-row {
      margin-bottom: 30px;
      color: rgb(51, 51, 51);
      font-weight: bold;
      &:first-child {
        font-size: 40px;
      }
      &:nth-child(2) {
        font-size: 35px;
      }
    }
  }
}

.download {
  color: #fff;
  cursor: pointer;
  max-width: 200px;
  font-weight: 700;
  padding: 10px 30px;
  background: linear-gradient(313deg, #009dff, #76c7f9);
  box-shadow: 0 6px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 39px 39px 39px 39px;
  opacity: 1;
  border: 0;
  &:hover {
    color: #fff;
  }
}

//页脚

.footer {
  text-align: center;
}

// 背景图
.swiper {
  background: url("./assets/drawnote_bg.png");
  background-size: cover;
}

//“吾绘笔记可以做出怎样的笔记”
.swiperBox {
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}
.swiperLi {
  margin: 10px;
  flex-shrink: 0;
  flex-basis: auto;
}
</style>

<style lang="less">
.download-box {
  .el-row {
    .el-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .el-button {
        margin-top: 5px;
        height: 32px;
        padding: 5px;
        color: rgb(253, 102, 55);
        border: 1px solid rgb(253, 102, 55);
        background: rgb(255, 255, 255);
      }
    }
  }
}
.home-page {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body,
h4 {
  margin: 0;
  padding: 0;
}
html,
body,
.el-container {
  padding: 0px;
  margin: 0px;
  height: 100%;
}
a {
  text-decoration: none;
}
body {
  margin: 8px;
}
.el-header {
  padding: 0 !important;
  height: fit-content !important;
}
.el-main {
  padding: 0 !important;
  overflow: unset !important;
}
</style>
