export default {
  title: "吾绘笔记",
  nav1: "首页",
  nav2: "使用手册",
  nav3: "有为商城",
  download: "下载",
  lang: "EN",
  swiperText1: "吾绘笔记",
  swiperText2: "每一个“我”皆热爱记录和创作",
  swiperText3:
    "集合了笔记、写作、思维导图、待办事项清单、手写、素描、绘图和绘画等多项功能",
  swiperButton: "立即下载",
  dialogButton1: "打开应用市场",
  dialogButton2: "打开苹果商店",

  footerNumber: "2024惠州吾绘科技有限公司 版权所有",
  footerNumber_icp: "粤ICP备2024209523号",
};
