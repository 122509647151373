/**
 * cookie操作
 */
export const getCookie = function (name, value, options) {
  if (typeof value != "undefined") {
    // name and value given, set cookie
    options = options || {};
    if (value === null) {
      value = "";
      options.expires = -1;
    }
    var expires = "";
    if (
      options.expires &&
      (typeof options.expires == "number" || options.expires.toUTCString)
    ) {
      var date;
      if (typeof options.expires == "number") {
        date = new Date();
        date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000);
      } else {
        date = options.expires;
      }
      expires = "; expires=" + date.toUTCString(); // use expires attribute, max-age is not supported by IE
    }
    var path = options.path ? "; path=" + options.path : "";
    var domain = options.domain ? "; domain=" + options.domain : "";
    // var s = [cookie, expires, path, domain, secure].join("");
    var secure = options.secure ? "; secure" : "";
    var c = [name, "=", encodeURIComponent(value)].join("");
    var cookie = [c, expires, path, domain, secure].join("");
    document.cookie = cookie;
  } else {
    // only name given, get cookie
    var cookieValue = null;
    if (document.cookie && document.cookie != "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) == name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
};
/**
 * 获取浏览器语言类型
 */
export const getNavLanguage = function () {
  let navLanguage = (navigator.browserLanguage || navigator.language)
    .toLowerCase()
    .slice(0, 2);
  switch (navLanguage) {
    case "zh":
      navLanguage = "cn";
      break;
    case "ko":
      navLanguage = "ko";
      break;
    default:
      navLanguage = "en";
  }
  return navLanguage;
};
