import Vue from "vue";
import VueI18n from "vue-i18n";
import { getCookie, getNavLanguage } from "./cookie";
import cnLanguage from "@/assets/lang/cn.js";
import enLanguage from "@/assets/lang/en.js";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "cn", // 语言标识
  fallbackLocale: "en",
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    // 引入语言包
    cn: cnLanguage,
    en: enLanguage,
  }
});

i18n.setUserLanguage = function (lang) {
  getCookie("userLanguage", lang, {
    expires: 30,
    path: "/",
  });

  i18n.locale = lang;
};
const cookieLang = getCookie("userLanguage")
  ? getCookie("userLanguage")
  : getNavLanguage();

i18n.setUserLanguage(cookieLang);

export default i18n;
